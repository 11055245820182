//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/functions';
@import '../../globals/scss/vars';
@import 'functions';

/// UI shell content
/// @access private
/// @group ui-shell
@mixin carbon-content {
  .#{$prefix}--content {
    padding: 2rem;
    background: $ui-02;
    transform: translate3d(0, 0, 0);
    will-change: margin-left;
  }

  .#{$prefix}--header ~ .#{$prefix}--content {
    margin-top: mini-units(6);
  }

  .#{$prefix}--side-nav ~ .#{$prefix}--content {
    margin-left: mini-units(6);
  }

  .#{$prefix}--side-nav.#{$prefix}--side-nav--expanded ~ .#{$prefix}--content {
    margin-left: mini-units(32);
  }
}

@include exports('carbon-content') {
  @if feature-flag-enabled('ui-shell') {
    @include carbon-content;
  }
}
