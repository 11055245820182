//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import 'variables';

/// Get a `rem` value based for a number of mini-units.
/// @param {Number} $count
/// @returns {Number} In rem
/// @group ui-shell
@function mini-units($count) {
  @return $unit * $count;
}
