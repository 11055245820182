//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access private
/// @group loading
$loading__gap: 40;

/// @type Number
/// @access private
/// @group loading
$loading--small__gap: 99;

/// @type Number
/// @access private
/// @group loading
$loading__size: 10.5rem;
