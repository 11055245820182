//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

//----------------------------------------------
// Inline Notification
// ---------------------------------------------

/// @access private
/// @group notification
@mixin inline-notification--color($color) {
  border: 1px solid $color;
  border-left: 6px solid $color;

  .#{$prefix}--inline-notification__icon {
    fill: $color;
  }
}

//----------------------------------------------
// Toast Notification
// ---------------------------------------------

/// @access private
/// @group notification
@mixin notification--color($color) {
  border-left: 6px solid $color;
}

/// @access private
/// @group notification
@mixin notification--experimental($color, $background-color) {
  background: $background-color;
  border-left: 3px solid $color;

  .#{$prefix}--inline-notification__icon,
  .#{$prefix}--toast-notification__icon {
    fill: $color;
  }
}
