//
// Copyright IBM Corp. 2018, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// Font family fallbacks for: IBM Plex Mono, IBM Plex Sans, IBM Plex Sans
/// Condensed, IBM Plex Sans Hebrew, and IBM Plex Serif
/// @type Map
/// @access public
/// @group @carbon/type
$carbon--font-families: (
  'mono':
    unquote(
      "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
    ),
  'sans': unquote("'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif"),
  'sans-condensed':
    unquote("'IBM Plex Sans Condensed', 'Helvetica Neue', Arial, sans-serif"),
  'sans-hebrew':
    unquote(
      "'IBM Plex Sans Hebrew', 'Helvetica Hebrew', 'Arial Hebrew', sans-serif"
    ),
  'serif': unquote("'IBM Plex Serif', 'Georgia', Times, serif"),
) !default;

/// Get the font-family for an IBM Plex font
/// @param {String} $name
/// @return {String}
/// @access public
/// @group @carbon/type
@function carbon--font-family($name) {
  @return map-get($carbon--font-families, $name);
}

/// Include the `font-family` definition for the given name in your selector
/// @param {String} $name
/// @access public
/// @group @carbon/type
@mixin carbon--font-family($name) {
  font-family: carbon--font-family($name);
}

/// Suggested font weights to be used in product
/// @type Map
/// @access public
/// @group @carbon/type
$carbon--font-weights: (
  'light': 300,
  'regular': 400,
  'semibold': 600,
) !default;

/// Retrieve the font-weight value for a given name
/// @param {String} $weight
/// @return {Number}
/// @access public
/// @group @carbon/type
@function carbon--font-weight($weight) {
  @return map-get($carbon--font-weights, $weight);
}

/// Set the `font-weight` property with the value for a given name
/// @param {String} $weight
/// @access public
/// @group @carbon/type
@mixin carbon--font-weight($weight) {
  font-weight: carbon--font-weight($weight);
}
