//
// Copyright IBM Corp. 2018, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// Defines how font files are loaded and displayed by the browser
/// @link https://css-tricks.com/almanac/properties/f/font-display/
/// @access public
/// @group @carbon/type
$carbon--font-display: auto !default;
