//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import './vendor/@carbon/elements/scss/import-once/import-once';
@import './vendor/@carbon/elements/scss/type/reset';
@import './vendor/@carbon/elements/scss/icons/mixins';

/// Resets default browser styling
/// @access public
/// @group global-reset
@mixin reset {
  @if variable-exists(css--reset) == false or $css--reset == false {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    border: 0;

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
  }
}

@include exports('css--reset') {
  @if variable-exists(css--reset) == false or $css--reset == true {
    // http://cssreset.com/scripts/eric-meyer-reset-css/
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
      border: 0;
    }

    // Chrome 62 fix
    button,
    select,
    input,
    textarea {
      font-family: inherit;
      border-radius: 0;
    }

    input[type='text']::-ms-clear {
      display: none;
    }

    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section {
      display: block;
    }

    body {
      line-height: 1;
    }

    sup {
      vertical-align: super;
    }

    sub {
      vertical-align: sub;
    }

    ol,
    ul {
      list-style: none;
    }

    blockquote,
    q {
      quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
      content: '';
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    * {
      box-sizing: border-box;
    }

    // Fix to remove added margins on buttons in safari (see #5155)
    button {
      margin: 0;
    }

    @include carbon--type-reset;
    @include carbon--icons;
  }
}

@include exports('css--default-type') {
  @if variable-exists(css--default-type) == false or $css--default-type == true
  {
    @include carbon--default-type;
  }
}
