//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/vars';
@import '../../globals/scss/css--reset';
@import '../../globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import 'keyframes';
@import 'mixins';
@import 'vars';

/// Loading styles
/// @access private
/// @group loading
@mixin loading {
  .#{$prefix}--loading {
    @include reset;
    @include animation__loading--spin;

    width: $loading__size;
    height: $loading__size;
  }

  // Animation (Spin by default)
  .#{$prefix}--loading__svg {
    fill: transparent;
  }

  .#{$prefix}--loading__svg circle {
    stroke-width: 8;
    stroke-linecap: butt;
    stroke-dasharray: 240;
  }

  .#{$prefix}--loading__stroke {
    stroke: $interactive-04;
    stroke-dashoffset: $loading__gap;
  }

  .#{$prefix}--loading--small .#{$prefix}--loading__stroke {
    stroke-dashoffset: $loading--small__gap;
  }

  .#{$prefix}--loading--stop {
    @include animation__loading--stop;
  }

  .#{$prefix}--loading--small {
    width: 2rem;
    height: 2rem;
    circle {
      stroke-width: 12;
    }
  }

  .#{$prefix}--loading--small .#{$prefix}--loading__svg {
    stroke: $interactive-04;
  }

  .#{$prefix}--loading__background {
    stroke: $ui-03;
    stroke-dashoffset: 0;
  }

  .#{$prefix}--loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('overlay');
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $overlay-01;
    transition: background-color $duration--slow-02 motion(standard, expressive);
  }

  .#{$prefix}--loading-overlay--stop {
    display: none;
  }
}

@include exports('loading') {
  @include loading;
}
