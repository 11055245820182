//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import 'vars';
@import './vendor/@carbon/elements/scss/import-once/import-once';

@include exports('helper-classes') {
  .#{$prefix}--text-truncate--end {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .#{$prefix}--text-truncate--front {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    direction: rtl;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
