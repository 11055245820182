//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/helper-mixins';
@import '../../globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import '../../globals/scss/vars';

@include exports('skeleton-icon') {
  .#{$prefix}--icon--skeleton {
    @include skeleton;

    display: inline-block;
    width: rem(16px);
    height: rem(16px);
  }
}
