//
// Copyright IBM Corp. 2018, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// Makes SVGs accessible in high contrast mode
/// @link https://github.com/IBM/carbon-elements/issues/345#issuecomment-466577293 Carbon-elements #345
/// @access public
/// @group @carbon/icons
@mixin carbon--icons {
  @media screen and (-ms-high-contrast: active) {
    svg {
      fill: ButtonText;
    }
  }
}
