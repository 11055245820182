// Code generated by @carbon/themes. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

$carbon--theme--white: (
  interactive-01: #0f62fe,
  interactive-02: #393939,
  interactive-03: #0f62fe,
  interactive-04: #0f62fe,
  ui-background: #ffffff,
  ui-01: #f4f4f4,
  ui-02: #ffffff,
  ui-03: #e0e0e0,
  ui-04: #8d8d8d,
  ui-05: #161616,
  text-01: #161616,
  text-02: #525252,
  text-03: #a8a8a8,
  text-04: #ffffff,
  text-05: #6f6f6f,
  text-error: #da1e28,
  icon-01: #161616,
  icon-02: #525252,
  icon-03: #ffffff,
  link-01: #0f62fe,
  inverse-link: #78a9ff,
  field-01: #f4f4f4,
  field-02: #ffffff,
  inverse-01: #ffffff,
  inverse-02: #393939,
  support-01: #da1e28,
  support-02: #24a148,
  support-03: #f1c21b,
  support-04: #0043ce,
  inverse-support-01: #fa4d56,
  inverse-support-02: #42be65,
  inverse-support-03: #f1c21b,
  inverse-support-04: #4589ff,
  overlay-01: rgba(22, 22, 22, 0.5),
  danger: #da1e28,
  focus: #0f62fe,
  inverse-focus-ui: #ffffff,
  hover-primary: #0353e9,
  active-primary: #002d9c,
  hover-primary-text: #0043ce,
  hover-secondary: #4c4c4c,
  active-secondary: #6f6f6f,
  hover-tertiary: #0353e9,
  active-tertiary: #002d9c,
  hover-ui: #e5e5e5,
  active-ui: #c6c6c6,
  selected-ui: #e0e0e0,
  selected-light-ui: #e0e0e0,
  inverse-hover-ui: #4c4c4c,
  hover-selected-ui: #cacaca,
  hover-danger: #b81921,
  active-danger: #750e13,
  hover-row: #e5e5e5,
  visited-link: #8a3ffc,
  disabled-01: #f4f4f4,
  disabled-02: #c6c6c6,
  disabled-03: #8d8d8d,
  highlight: #d0e2ff,
  decorative-01: #e0e0e0,
  hover-light-ui: #e5e5e5,
  button-separator: #e0e0e0,
  skeleton-01: #e5e5e5,
  skeleton-02: #c6c6c6,
  brand-01: #0f62fe,
  brand-02: #393939,
  brand-03: #0f62fe,
  active-01: #c6c6c6,
  hover-field: #e5e5e5,
  caption-01: (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  ),
  label-01: (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  ),
  helper-text-01: (
    font-size: 0.75rem,
    line-height: 1.34,
    letter-spacing: 0.32px,
  ),
  body-short-01: (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0.16px,
  ),
  body-long-01: (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.16px,
  ),
  body-short-02: (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.375,
    letter-spacing: 0,
  ),
  body-long-02: (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.5,
    letter-spacing: 0,
  ),
  code-01: (
    font-family:
      unquote(
        "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  ),
  code-02: (
    font-family:
      unquote(
        "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.32px,
  ),
  heading-01: (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0.16px,
  ),
  productive-heading-01: (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0.16px,
  ),
  heading-02: (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  ),
  productive-heading-02: (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  ),
  productive-heading-03: (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.4,
    letter-spacing: 0,
  ),
  productive-heading-04: (
    font-size: 1.75rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0,
  ),
  productive-heading-05: (
    font-size: 2rem,
    font-weight: 400,
    line-height: 1.25,
    letter-spacing: 0,
  ),
  productive-heading-06: (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.199,
    letter-spacing: 0,
  ),
  productive-heading-07: (
    font-size: 3.375rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
  ),
  expressive-heading-01: (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0.16px,
  ),
  expressive-heading-02: (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.5,
    letter-spacing: 0,
  ),
  expressive-heading-03: (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.4,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.25rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 1.5rem,
        line-height: 1.334,
      ),
    ),
  ),
  expressive-heading-04: (
    font-size: 1.75rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.75rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 2rem,
      ),
    ),
  ),
  expressive-heading-05: (
    font-size: 2rem,
    font-weight: 400,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 300,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 300,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 300,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 300,
        letter-spacing: 0,
      ),
    ),
  ),
  expressive-heading-06: (
    font-size: 2rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 600,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 600,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 600,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 600,
        letter-spacing: 0,
      ),
    ),
  ),
  expressive-paragraph-01: (
    font-size: 1.5rem,
    font-weight: 300,
    line-height: 1.334,
    letter-spacing: 0,
    lg: (
      font-size: 1.75rem,
      line-height: 1.29,
    ),
    max: (
      font-size: 2rem,
      line-height: 1.25,
    ),
  ),
  quotation-01: (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.3,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 1.25rem,
        font-weight: 400,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 1.5rem,
        font-weight: 400,
        line-height: 1.334,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 1.75rem,
        font-weight: 400,
        line-height: 1.29,
        letter-spacing: 0,
      ),
      max: (
        font-size: 2rem,
        font-weight: 400,
        line-height: 1.25,
        letter-spacing: 0,
      ),
    ),
  ),
  quotation-02: (
    font-size: 2rem,
    font-weight: 300,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        line-height: 1.22,
      ),
      lg: (
        font-size: 2.625rem,
        line-height: 1.19,
      ),
      xlg: (
        font-size: 3rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 3.75rem,
      ),
    ),
  ),
  display-01: (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  ),
  display-02: (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.16,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  ),
  display-03: (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  ),
  display-04: (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
        letter-spacing: -0.64px,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  ),
  spacing-01: 0.125rem,
  spacing-02: 0.25rem,
  spacing-03: 0.5rem,
  spacing-04: 0.75rem,
  spacing-05: 1rem,
  spacing-06: 1.5rem,
  spacing-07: 2rem,
  spacing-08: 2.5rem,
  spacing-09: 3rem,
  spacing-10: 4rem,
  spacing-11: 5rem,
  spacing-12: 6rem,
  fluid-spacing-01: 0,
  fluid-spacing-02: 2vw,
  fluid-spacing-03: 5vw,
  fluid-spacing-04: 10vw,
  layout-01: 1rem,
  layout-02: 1.5rem,
  layout-03: 2rem,
  layout-04: 3rem,
  layout-05: 4rem,
  layout-06: 6rem,
  layout-07: 10rem,
  container-01: 1.5rem,
  container-02: 2rem,
  container-03: 2.5rem,
  container-04: 3rem,
  container-05: 4rem,
  icon-size-01: 1rem,
  icon-size-02: 1.25rem,
) !default;

/// Carbon's g10 color theme
/// @type Map
/// @access public
/// @group @carbon/themes
$carbon--theme--g10: map-merge(
  $carbon--theme--white,
  (
    ui-background: #f4f4f4,
    ui-01: #ffffff,
    ui-02: #f4f4f4,
    field-01: #ffffff,
    field-02: #f4f4f4,
    disabled-01: #ffffff,
  )
) !default;

/// Carbon's g90 color theme
/// @type Map
/// @access public
/// @group @carbon/themes
$carbon--theme--g90: map-merge(
  $carbon--theme--white,
  (
    interactive-02: #6f6f6f,
    interactive-03: #ffffff,
    interactive-04: #4589ff,
    ui-background: #262626,
    ui-01: #393939,
    ui-02: #525252,
    ui-03: #525252,
    ui-05: #f4f4f4,
    text-01: #f4f4f4,
    text-02: #c6c6c6,
    text-03: #6f6f6f,
    text-05: #8d8d8d,
    text-error: #ffb3b8,
    icon-01: #f4f4f4,
    icon-02: #c6c6c6,
    link-01: #78a9ff,
    inverse-link: #0f62fe,
    field-01: #393939,
    field-02: #525252,
    inverse-01: #161616,
    inverse-02: #f4f4f4,
    support-01: #ff8389,
    support-02: #42be65,
    support-04: #4589ff,
    inverse-support-01: #da1e28,
    inverse-support-02: #24a148,
    inverse-support-04: #0f62fe,
    overlay-01: rgba(22, 22, 22, 0.7),
    focus: #ffffff,
    inverse-focus-ui: #0f62fe,
    hover-primary-text: #a6c8ff,
    hover-secondary: #606060,
    active-secondary: #393939,
    hover-tertiary: #f4f4f4,
    active-tertiary: #c6c6c6,
    hover-ui: #4c4c4c,
    active-ui: #6f6f6f,
    selected-ui: #525252,
    selected-light-ui: #6f6f6f,
    inverse-hover-ui: #e5e5e5,
    hover-selected-ui: #656565,
    hover-row: #4c4c4c,
    visited-link: #be95ff,
    disabled-01: #393939,
    disabled-02: #6f6f6f,
    disabled-03: #a8a8a8,
    highlight: #0043ce,
    decorative-01: #6f6f6f,
    hover-light-ui: #6f6f6f,
    button-separator: #161616,
    skeleton-01: #353535,
    skeleton-02: #525252,
    brand-02: #6f6f6f,
    brand-03: #ffffff,
    active-01: #6f6f6f,
    hover-field: #4c4c4c,
  )
) !default;

/// Carbon's g100 color theme
/// @type Map
/// @access public
/// @group @carbon/themes
$carbon--theme--g100: map-merge(
  $carbon--theme--white,
  (
    interactive-02: #6f6f6f,
    interactive-03: #ffffff,
    interactive-04: #4589ff,
    ui-background: #161616,
    ui-01: #262626,
    ui-02: #393939,
    ui-03: #393939,
    ui-04: #6f6f6f,
    ui-05: #f4f4f4,
    text-01: #f4f4f4,
    text-02: #c6c6c6,
    text-03: #6f6f6f,
    text-05: #8d8d8d,
    text-error: #ff8389,
    icon-01: #f4f4f4,
    icon-02: #c6c6c6,
    link-01: #78a9ff,
    inverse-link: #0f62fe,
    field-01: #262626,
    field-02: #393939,
    inverse-01: #161616,
    inverse-02: #f4f4f4,
    support-01: #fa4d56,
    support-02: #42be65,
    support-04: #4589ff,
    inverse-support-01: #da1e28,
    inverse-support-02: #24a148,
    inverse-support-04: #0f62fe,
    overlay-01: rgba(22, 22, 22, 0.7),
    focus: #ffffff,
    inverse-focus-ui: #0f62fe,
    hover-primary-text: #a6c8ff,
    hover-secondary: #606060,
    active-secondary: #393939,
    hover-tertiary: #f4f4f4,
    active-tertiary: #c6c6c6,
    hover-ui: #353535,
    active-ui: #525252,
    selected-ui: #393939,
    selected-light-ui: #525252,
    inverse-hover-ui: #e5e5e5,
    hover-selected-ui: #4c4c4c,
    hover-row: #353535,
    visited-link: #be95ff,
    disabled-01: #262626,
    disabled-02: #525252,
    highlight: #002d9c,
    decorative-01: #525252,
    hover-light-ui: #525252,
    button-separator: #161616,
    skeleton-01: #353535,
    skeleton-02: #393939,
    brand-02: #6f6f6f,
    brand-03: #ffffff,
    active-01: #525252,
    hover-field: #353535,
  )
) !default;

/// Carbon's v9 color theme
/// @type Map
/// @access public
/// @group @carbon/themes
$carbon--theme--v9: map-merge(
  $carbon--theme--white,
  (
    interactive-01: #3d70b2,
    interactive-02: #4d5358,
    interactive-03: #3d70b2,
    interactive-04: #3d70b2,
    ui-background: #f4f7fb,
    ui-01: #ffffff,
    ui-02: #f4f7fb,
    ui-03: #dfe3e6,
    ui-04: #8897a2,
    ui-05: #5a6872,
    text-01: #152935,
    text-02: #5a6872,
    text-03: #cdd1d4,
    text-05: #5a6872,
    text-error: #e0182d,
    icon-01: #3d70b2,
    icon-02: #5a6872,
    link-01: #3d70b2,
    inverse-link: #5596e6,
    field-01: #ffffff,
    field-02: #f4f7fb,
    inverse-02: #272d33,
    support-01: #e0182d,
    support-02: #5aa700,
    support-03: #efc100,
    support-04: #5aaafa,
    inverse-support-01: #ff5050,
    inverse-support-02: #8cd211,
    inverse-support-03: #fdd600,
    inverse-support-04: #5aaafa,
    overlay-01: rgba(223, 227, 230, 0.5),
    focus: #3d70b2,
    inverse-focus-ui: #3d70b2,
    hover-primary: #30588c,
    active-primary: #30588c,
    hover-primary-text: #294c86,
    hover-secondary: #4d5b65,
    active-secondary: #414f59,
    hover-tertiary: #5a6872,
    active-tertiary: #414f59,
    hover-ui: #eef4fc,
    active-ui: #dfeafa,
    selected-ui: #eef4fc,
    selected-light-ui: #eef4fc,
    hover-selected-ui: #dfeafa,
    hover-danger: #c70014,
    active-danger: #ad1625,
    hover-row: #eef4fc,
    visited-link: #294c86,
    disabled-01: #fafbfd,
    disabled-02: #dfe3e6,
    disabled-03: #cdd1d4,
    highlight: #f4f7fb,
    decorative-01: #eef4fc,
    hover-light-ui: #eef4fc,
    skeleton-01: rgba(61, 112, 178, 0.1),
    skeleton-02: rgba(61, 112, 178, 0.1),
    brand-01: #3d70b2,
    brand-02: #4d5358,
    brand-03: #3d70b2,
    active-01: #dfeafa,
    hover-field: #eef4fc,
  )
) !default;

/// Carbon's default theme
/// @type Map
/// @access public
/// @alias carbon--theme--white
/// @group @carbon/themes
$carbon--theme: (
  interactive-01:
    if(
      global-variable-exists('interactive-01'),
      $interactive-01,
      map-get($carbon--theme--white, 'interactive-01')
    ),
  interactive-02:
    if(
      global-variable-exists('interactive-02'),
      $interactive-02,
      map-get($carbon--theme--white, 'interactive-02')
    ),
  interactive-03:
    if(
      global-variable-exists('interactive-03'),
      $interactive-03,
      map-get($carbon--theme--white, 'interactive-03')
    ),
  interactive-04:
    if(
      global-variable-exists('interactive-04'),
      $interactive-04,
      map-get($carbon--theme--white, 'interactive-04')
    ),
  ui-background:
    if(
      global-variable-exists('ui-background'),
      $ui-background,
      map-get($carbon--theme--white, 'ui-background')
    ),
  ui-01:
    if(
      global-variable-exists('ui-01'),
      $ui-01,
      map-get($carbon--theme--white, 'ui-01')
    ),
  ui-02:
    if(
      global-variable-exists('ui-02'),
      $ui-02,
      map-get($carbon--theme--white, 'ui-02')
    ),
  ui-03:
    if(
      global-variable-exists('ui-03'),
      $ui-03,
      map-get($carbon--theme--white, 'ui-03')
    ),
  ui-04:
    if(
      global-variable-exists('ui-04'),
      $ui-04,
      map-get($carbon--theme--white, 'ui-04')
    ),
  ui-05:
    if(
      global-variable-exists('ui-05'),
      $ui-05,
      map-get($carbon--theme--white, 'ui-05')
    ),
  text-01:
    if(
      global-variable-exists('text-01'),
      $text-01,
      map-get($carbon--theme--white, 'text-01')
    ),
  text-02:
    if(
      global-variable-exists('text-02'),
      $text-02,
      map-get($carbon--theme--white, 'text-02')
    ),
  text-03:
    if(
      global-variable-exists('text-03'),
      $text-03,
      map-get($carbon--theme--white, 'text-03')
    ),
  text-04:
    if(
      global-variable-exists('text-04'),
      $text-04,
      map-get($carbon--theme--white, 'text-04')
    ),
  text-05:
    if(
      global-variable-exists('text-05'),
      $text-05,
      map-get($carbon--theme--white, 'text-05')
    ),
  text-error:
    if(
      global-variable-exists('text-error'),
      $text-error,
      map-get($carbon--theme--white, 'text-error')
    ),
  icon-01:
    if(
      global-variable-exists('icon-01'),
      $icon-01,
      map-get($carbon--theme--white, 'icon-01')
    ),
  icon-02:
    if(
      global-variable-exists('icon-02'),
      $icon-02,
      map-get($carbon--theme--white, 'icon-02')
    ),
  icon-03:
    if(
      global-variable-exists('icon-03'),
      $icon-03,
      map-get($carbon--theme--white, 'icon-03')
    ),
  link-01:
    if(
      global-variable-exists('link-01'),
      $link-01,
      map-get($carbon--theme--white, 'link-01')
    ),
  inverse-link:
    if(
      global-variable-exists('inverse-link'),
      $inverse-link,
      map-get($carbon--theme--white, 'inverse-link')
    ),
  field-01:
    if(
      global-variable-exists('field-01'),
      $field-01,
      map-get($carbon--theme--white, 'field-01')
    ),
  field-02:
    if(
      global-variable-exists('field-02'),
      $field-02,
      map-get($carbon--theme--white, 'field-02')
    ),
  inverse-01:
    if(
      global-variable-exists('inverse-01'),
      $inverse-01,
      map-get($carbon--theme--white, 'inverse-01')
    ),
  inverse-02:
    if(
      global-variable-exists('inverse-02'),
      $inverse-02,
      map-get($carbon--theme--white, 'inverse-02')
    ),
  support-01:
    if(
      global-variable-exists('support-01'),
      $support-01,
      map-get($carbon--theme--white, 'support-01')
    ),
  support-02:
    if(
      global-variable-exists('support-02'),
      $support-02,
      map-get($carbon--theme--white, 'support-02')
    ),
  support-03:
    if(
      global-variable-exists('support-03'),
      $support-03,
      map-get($carbon--theme--white, 'support-03')
    ),
  support-04:
    if(
      global-variable-exists('support-04'),
      $support-04,
      map-get($carbon--theme--white, 'support-04')
    ),
  inverse-support-01:
    if(
      global-variable-exists('inverse-support-01'),
      $inverse-support-01,
      map-get($carbon--theme--white, 'inverse-support-01')
    ),
  inverse-support-02:
    if(
      global-variable-exists('inverse-support-02'),
      $inverse-support-02,
      map-get($carbon--theme--white, 'inverse-support-02')
    ),
  inverse-support-03:
    if(
      global-variable-exists('inverse-support-03'),
      $inverse-support-03,
      map-get($carbon--theme--white, 'inverse-support-03')
    ),
  inverse-support-04:
    if(
      global-variable-exists('inverse-support-04'),
      $inverse-support-04,
      map-get($carbon--theme--white, 'inverse-support-04')
    ),
  overlay-01:
    if(
      global-variable-exists('overlay-01'),
      $overlay-01,
      map-get($carbon--theme--white, 'overlay-01')
    ),
  danger:
    if(
      global-variable-exists('danger'),
      $danger,
      map-get($carbon--theme--white, 'danger')
    ),
  focus:
    if(
      global-variable-exists('focus'),
      $focus,
      map-get($carbon--theme--white, 'focus')
    ),
  inverse-focus-ui:
    if(
      global-variable-exists('inverse-focus-ui'),
      $inverse-focus-ui,
      map-get($carbon--theme--white, 'inverse-focus-ui')
    ),
  hover-primary:
    if(
      global-variable-exists('hover-primary'),
      $hover-primary,
      map-get($carbon--theme--white, 'hover-primary')
    ),
  active-primary:
    if(
      global-variable-exists('active-primary'),
      $active-primary,
      map-get($carbon--theme--white, 'active-primary')
    ),
  hover-primary-text:
    if(
      global-variable-exists('hover-primary-text'),
      $hover-primary-text,
      map-get($carbon--theme--white, 'hover-primary-text')
    ),
  hover-secondary:
    if(
      global-variable-exists('hover-secondary'),
      $hover-secondary,
      map-get($carbon--theme--white, 'hover-secondary')
    ),
  active-secondary:
    if(
      global-variable-exists('active-secondary'),
      $active-secondary,
      map-get($carbon--theme--white, 'active-secondary')
    ),
  hover-tertiary:
    if(
      global-variable-exists('hover-tertiary'),
      $hover-tertiary,
      map-get($carbon--theme--white, 'hover-tertiary')
    ),
  active-tertiary:
    if(
      global-variable-exists('active-tertiary'),
      $active-tertiary,
      map-get($carbon--theme--white, 'active-tertiary')
    ),
  hover-ui:
    if(
      global-variable-exists('hover-ui'),
      $hover-ui,
      map-get($carbon--theme--white, 'hover-ui')
    ),
  active-ui:
    if(
      global-variable-exists('active-ui'),
      $active-ui,
      map-get($carbon--theme--white, 'active-ui')
    ),
  selected-ui:
    if(
      global-variable-exists('selected-ui'),
      $selected-ui,
      map-get($carbon--theme--white, 'selected-ui')
    ),
  selected-light-ui:
    if(
      global-variable-exists('selected-light-ui'),
      $selected-light-ui,
      map-get($carbon--theme--white, 'selected-light-ui')
    ),
  hover-selected-ui:
    if(
      global-variable-exists('hover-selected-ui'),
      $hover-selected-ui,
      map-get($carbon--theme--white, 'hover-selected-ui')
    ),
  inverse-hover-ui:
    if(
      global-variable-exists('inverse-hover-ui'),
      $inverse-hover-ui,
      map-get($carbon--theme--white, 'inverse-hover-ui')
    ),
  hover-danger:
    if(
      global-variable-exists('hover-danger'),
      $hover-danger,
      map-get($carbon--theme--white, 'hover-danger')
    ),
  active-danger:
    if(
      global-variable-exists('active-danger'),
      $active-danger,
      map-get($carbon--theme--white, 'active-danger')
    ),
  hover-row:
    if(
      global-variable-exists('hover-row'),
      $hover-row,
      map-get($carbon--theme--white, 'hover-row')
    ),
  visited-link:
    if(
      global-variable-exists('visited-link'),
      $visited-link,
      map-get($carbon--theme--white, 'visited-link')
    ),
  disabled-01:
    if(
      global-variable-exists('disabled-01'),
      $disabled-01,
      map-get($carbon--theme--white, 'disabled-01')
    ),
  disabled-02:
    if(
      global-variable-exists('disabled-02'),
      $disabled-02,
      map-get($carbon--theme--white, 'disabled-02')
    ),
  disabled-03:
    if(
      global-variable-exists('disabled-03'),
      $disabled-03,
      map-get($carbon--theme--white, 'disabled-03')
    ),
  highlight:
    if(
      global-variable-exists('highlight'),
      $highlight,
      map-get($carbon--theme--white, 'highlight')
    ),
  decorative-01:
    if(
      global-variable-exists('decorative-01'),
      $decorative-01,
      map-get($carbon--theme--white, 'decorative-01')
    ),
  hover-light-ui:
    if(
      global-variable-exists('hover-light-ui'),
      $hover-light-ui,
      map-get($carbon--theme--white, 'hover-light-ui')
    ),
  button-separator:
    if(
      global-variable-exists('button-separator'),
      $button-separator,
      map-get($carbon--theme--white, 'button-separator')
    ),
  skeleton-01:
    if(
      global-variable-exists('skeleton-01'),
      $skeleton-01,
      map-get($carbon--theme--white, 'skeleton-01')
    ),
  skeleton-02:
    if(
      global-variable-exists('skeleton-02'),
      $skeleton-02,
      map-get($carbon--theme--white, 'skeleton-02')
    ),
  brand-01:
    if(
      global-variable-exists('brand-01'),
      $brand-01,
      map-get($carbon--theme--white, 'brand-01')
    ),
  brand-02:
    if(
      global-variable-exists('brand-02'),
      $brand-02,
      map-get($carbon--theme--white, 'brand-02')
    ),
  brand-03:
    if(
      global-variable-exists('brand-03'),
      $brand-03,
      map-get($carbon--theme--white, 'brand-03')
    ),
  active-01:
    if(
      global-variable-exists('active-01'),
      $active-01,
      map-get($carbon--theme--white, 'active-01')
    ),
  hover-field:
    if(
      global-variable-exists('hover-field'),
      $hover-field,
      map-get($carbon--theme--white, 'hover-field')
    ),
  caption-01:
    if(
      global-variable-exists('caption-01'),
      $caption-01,
      map-get($carbon--theme--white, 'caption-01')
    ),
  label-01:
    if(
      global-variable-exists('label-01'),
      $label-01,
      map-get($carbon--theme--white, 'label-01')
    ),
  helper-text-01:
    if(
      global-variable-exists('helper-text-01'),
      $helper-text-01,
      map-get($carbon--theme--white, 'helper-text-01')
    ),
  body-short-01:
    if(
      global-variable-exists('body-short-01'),
      $body-short-01,
      map-get($carbon--theme--white, 'body-short-01')
    ),
  body-long-01:
    if(
      global-variable-exists('body-long-01'),
      $body-long-01,
      map-get($carbon--theme--white, 'body-long-01')
    ),
  body-short-02:
    if(
      global-variable-exists('body-short-02'),
      $body-short-02,
      map-get($carbon--theme--white, 'body-short-02')
    ),
  body-long-02:
    if(
      global-variable-exists('body-long-02'),
      $body-long-02,
      map-get($carbon--theme--white, 'body-long-02')
    ),
  code-01:
    if(
      global-variable-exists('code-01'),
      $code-01,
      map-get($carbon--theme--white, 'code-01')
    ),
  code-02:
    if(
      global-variable-exists('code-02'),
      $code-02,
      map-get($carbon--theme--white, 'code-02')
    ),
  heading-01:
    if(
      global-variable-exists('heading-01'),
      $heading-01,
      map-get($carbon--theme--white, 'heading-01')
    ),
  productive-heading-01:
    if(
      global-variable-exists('productive-heading-01'),
      $productive-heading-01,
      map-get($carbon--theme--white, 'productive-heading-01')
    ),
  heading-02:
    if(
      global-variable-exists('heading-02'),
      $heading-02,
      map-get($carbon--theme--white, 'heading-02')
    ),
  productive-heading-02:
    if(
      global-variable-exists('productive-heading-02'),
      $productive-heading-02,
      map-get($carbon--theme--white, 'productive-heading-02')
    ),
  productive-heading-03:
    if(
      global-variable-exists('productive-heading-03'),
      $productive-heading-03,
      map-get($carbon--theme--white, 'productive-heading-03')
    ),
  productive-heading-04:
    if(
      global-variable-exists('productive-heading-04'),
      $productive-heading-04,
      map-get($carbon--theme--white, 'productive-heading-04')
    ),
  productive-heading-05:
    if(
      global-variable-exists('productive-heading-05'),
      $productive-heading-05,
      map-get($carbon--theme--white, 'productive-heading-05')
    ),
  productive-heading-06:
    if(
      global-variable-exists('productive-heading-06'),
      $productive-heading-06,
      map-get($carbon--theme--white, 'productive-heading-06')
    ),
  productive-heading-07:
    if(
      global-variable-exists('productive-heading-07'),
      $productive-heading-07,
      map-get($carbon--theme--white, 'productive-heading-07')
    ),
  expressive-heading-01:
    if(
      global-variable-exists('expressive-heading-01'),
      $expressive-heading-01,
      map-get($carbon--theme--white, 'expressive-heading-01')
    ),
  expressive-heading-02:
    if(
      global-variable-exists('expressive-heading-02'),
      $expressive-heading-02,
      map-get($carbon--theme--white, 'expressive-heading-02')
    ),
  expressive-heading-03:
    if(
      global-variable-exists('expressive-heading-03'),
      $expressive-heading-03,
      map-get($carbon--theme--white, 'expressive-heading-03')
    ),
  expressive-heading-04:
    if(
      global-variable-exists('expressive-heading-04'),
      $expressive-heading-04,
      map-get($carbon--theme--white, 'expressive-heading-04')
    ),
  expressive-heading-05:
    if(
      global-variable-exists('expressive-heading-05'),
      $expressive-heading-05,
      map-get($carbon--theme--white, 'expressive-heading-05')
    ),
  expressive-heading-06:
    if(
      global-variable-exists('expressive-heading-06'),
      $expressive-heading-06,
      map-get($carbon--theme--white, 'expressive-heading-06')
    ),
  expressive-paragraph-01:
    if(
      global-variable-exists('expressive-paragraph-01'),
      $expressive-paragraph-01,
      map-get($carbon--theme--white, 'expressive-paragraph-01')
    ),
  quotation-01:
    if(
      global-variable-exists('quotation-01'),
      $quotation-01,
      map-get($carbon--theme--white, 'quotation-01')
    ),
  quotation-02:
    if(
      global-variable-exists('quotation-02'),
      $quotation-02,
      map-get($carbon--theme--white, 'quotation-02')
    ),
  display-01:
    if(
      global-variable-exists('display-01'),
      $display-01,
      map-get($carbon--theme--white, 'display-01')
    ),
  display-02:
    if(
      global-variable-exists('display-02'),
      $display-02,
      map-get($carbon--theme--white, 'display-02')
    ),
  display-03:
    if(
      global-variable-exists('display-03'),
      $display-03,
      map-get($carbon--theme--white, 'display-03')
    ),
  display-04:
    if(
      global-variable-exists('display-04'),
      $display-04,
      map-get($carbon--theme--white, 'display-04')
    ),
  spacing-01:
    if(
      global-variable-exists('spacing-01'),
      $spacing-01,
      map-get($carbon--theme--white, 'spacing-01')
    ),
  spacing-02:
    if(
      global-variable-exists('spacing-02'),
      $spacing-02,
      map-get($carbon--theme--white, 'spacing-02')
    ),
  spacing-03:
    if(
      global-variable-exists('spacing-03'),
      $spacing-03,
      map-get($carbon--theme--white, 'spacing-03')
    ),
  spacing-04:
    if(
      global-variable-exists('spacing-04'),
      $spacing-04,
      map-get($carbon--theme--white, 'spacing-04')
    ),
  spacing-05:
    if(
      global-variable-exists('spacing-05'),
      $spacing-05,
      map-get($carbon--theme--white, 'spacing-05')
    ),
  spacing-06:
    if(
      global-variable-exists('spacing-06'),
      $spacing-06,
      map-get($carbon--theme--white, 'spacing-06')
    ),
  spacing-07:
    if(
      global-variable-exists('spacing-07'),
      $spacing-07,
      map-get($carbon--theme--white, 'spacing-07')
    ),
  spacing-08:
    if(
      global-variable-exists('spacing-08'),
      $spacing-08,
      map-get($carbon--theme--white, 'spacing-08')
    ),
  spacing-09:
    if(
      global-variable-exists('spacing-09'),
      $spacing-09,
      map-get($carbon--theme--white, 'spacing-09')
    ),
  spacing-10:
    if(
      global-variable-exists('spacing-10'),
      $spacing-10,
      map-get($carbon--theme--white, 'spacing-10')
    ),
  spacing-11:
    if(
      global-variable-exists('spacing-11'),
      $spacing-11,
      map-get($carbon--theme--white, 'spacing-11')
    ),
  spacing-12:
    if(
      global-variable-exists('spacing-12'),
      $spacing-12,
      map-get($carbon--theme--white, 'spacing-12')
    ),
  fluid-spacing-01:
    if(
      global-variable-exists('fluid-spacing-01'),
      $fluid-spacing-01,
      map-get($carbon--theme--white, 'fluid-spacing-01')
    ),
  fluid-spacing-02:
    if(
      global-variable-exists('fluid-spacing-02'),
      $fluid-spacing-02,
      map-get($carbon--theme--white, 'fluid-spacing-02')
    ),
  fluid-spacing-03:
    if(
      global-variable-exists('fluid-spacing-03'),
      $fluid-spacing-03,
      map-get($carbon--theme--white, 'fluid-spacing-03')
    ),
  fluid-spacing-04:
    if(
      global-variable-exists('fluid-spacing-04'),
      $fluid-spacing-04,
      map-get($carbon--theme--white, 'fluid-spacing-04')
    ),
  layout-01:
    if(
      global-variable-exists('layout-01'),
      $layout-01,
      map-get($carbon--theme--white, 'layout-01')
    ),
  layout-02:
    if(
      global-variable-exists('layout-02'),
      $layout-02,
      map-get($carbon--theme--white, 'layout-02')
    ),
  layout-03:
    if(
      global-variable-exists('layout-03'),
      $layout-03,
      map-get($carbon--theme--white, 'layout-03')
    ),
  layout-04:
    if(
      global-variable-exists('layout-04'),
      $layout-04,
      map-get($carbon--theme--white, 'layout-04')
    ),
  layout-05:
    if(
      global-variable-exists('layout-05'),
      $layout-05,
      map-get($carbon--theme--white, 'layout-05')
    ),
  layout-06:
    if(
      global-variable-exists('layout-06'),
      $layout-06,
      map-get($carbon--theme--white, 'layout-06')
    ),
  layout-07:
    if(
      global-variable-exists('layout-07'),
      $layout-07,
      map-get($carbon--theme--white, 'layout-07')
    ),
  container-01:
    if(
      global-variable-exists('container-01'),
      $container-01,
      map-get($carbon--theme--white, 'container-01')
    ),
  container-02:
    if(
      global-variable-exists('container-02'),
      $container-02,
      map-get($carbon--theme--white, 'container-02')
    ),
  container-03:
    if(
      global-variable-exists('container-03'),
      $container-03,
      map-get($carbon--theme--white, 'container-03')
    ),
  container-04:
    if(
      global-variable-exists('container-04'),
      $container-04,
      map-get($carbon--theme--white, 'container-04')
    ),
  container-05:
    if(
      global-variable-exists('container-05'),
      $container-05,
      map-get($carbon--theme--white, 'container-05')
    ),
  icon-size-01:
    if(
      global-variable-exists('icon-size-01'),
      $icon-size-01,
      map-get($carbon--theme--white, 'icon-size-01')
    ),
  icon-size-02:
    if(
      global-variable-exists('icon-size-02'),
      $icon-size-02,
      map-get($carbon--theme--white, 'icon-size-02')
    ),
) !default;
