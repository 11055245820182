// Code generated by @carbon/layout. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-01: 1rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-02: 1.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-03: 2rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-04: 3rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-05: 4rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-06: 6rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--layout-07: 10rem !default;

/// @type List
/// @access public
/// @group @carbon/layout
$carbon--layout: (
  $carbon--layout-01,
  $carbon--layout-02,
  $carbon--layout-03,
  $carbon--layout-04,
  $carbon--layout-05,
  $carbon--layout-06,
  $carbon--layout-07
);

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-01
$layout-01: $carbon--layout-01 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-02
$layout-02: $carbon--layout-02 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-03
$layout-03: $carbon--layout-03 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-04
$layout-04: $carbon--layout-04 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-05
$layout-05: $carbon--layout-05 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-06
$layout-06: $carbon--layout-06 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--layout-07
$layout-07: $carbon--layout-07 !default;
