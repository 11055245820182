// Code generated by @carbon/themes. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// Primary interactive color; Primary buttons
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$interactive-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'interactive-01'),
  map-get($carbon--theme, 'interactive-01'),
  #0f62fe
) !default;

/// Secondary interactive color; Secondary button
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$interactive-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'interactive-02'),
  map-get($carbon--theme, 'interactive-02'),
  #393939
) !default;

/// 4.5:1 AA contrast; Tertiary button
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$interactive-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'interactive-03'),
  map-get($carbon--theme, 'interactive-03'),
  #0f62fe
) !default;

/// 3:1 AA contrast; Selected elements; Active elements; Accent icons
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$interactive-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'interactive-04'),
  map-get($carbon--theme, 'interactive-04'),
  #0f62fe
) !default;

/// Default page background
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-background: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-background'),
  map-get($carbon--theme, 'ui-background'),
  #ffffff
) !default;

/// Primary container background; Secondary page background
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-01'),
  map-get($carbon--theme, 'ui-01'),
  #f4f4f4
) !default;

/// Primary page background; Secondary container background
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-02'),
  map-get($carbon--theme, 'ui-02'),
  #ffffff
) !default;

/// Subtle border; Tertiary background color
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-03'),
  map-get($carbon--theme, 'ui-03'),
  #e0e0e0
) !default;

/// 3:1 AA element contrast; Medium contrast border
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-04'),
  map-get($carbon--theme, 'ui-04'),
  #8d8d8d
) !default;

/// 4.5:1 AA element contrast; High contrast border; Emphasis elements
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$ui-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'ui-05'),
  map-get($carbon--theme, 'ui-05'),
  #161616
) !default;

/// Primary text; Body copy; Headers; Hover text color for `$text-02`
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-01'),
  map-get($carbon--theme, 'text-01'),
  #161616
) !default;

/// Secondary text; Input labels; Help text
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-02'),
  map-get($carbon--theme, 'text-02'),
  #525252
) !default;

/// Placeholder text
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-03'),
  map-get($carbon--theme, 'text-03'),
  #a8a8a8
) !default;

/// Text on interactive colors
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-04'),
  map-get($carbon--theme, 'text-04'),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-05'),
  map-get($carbon--theme, 'text-05'),
  #6f6f6f
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$text-error: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'text-error'),
  map-get($carbon--theme, 'text-error'),
  #da1e28
) !default;

/// Primary icons
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$icon-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'icon-01'),
  map-get($carbon--theme, 'icon-01'),
  #161616
) !default;

/// Secondary icons
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$icon-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'icon-02'),
  map-get($carbon--theme, 'icon-02'),
  #525252
) !default;

/// Tertiary icons; Icons on interactive colors; Icons on non-ui colors
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$icon-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'icon-03'),
  map-get($carbon--theme, 'icon-03'),
  #ffffff
) !default;

/// Primary links; Ghost button
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$link-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'link-01'),
  map-get($carbon--theme, 'link-01'),
  #0f62fe
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-link: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-link'),
  map-get($carbon--theme, 'inverse-link'),
  #78a9ff
) !default;

/// Default input fields; Field color on $ui-backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$field-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'field-01'),
  map-get($carbon--theme, 'field-01'),
  #f4f4f4
) !default;

/// Input field color on `$ui-02` backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$field-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'field-02'),
  map-get($carbon--theme, 'field-02'),
  #ffffff
) !default;

/// Inverse text color; Inverse icon color
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-01'),
  map-get($carbon--theme, 'inverse-01'),
  #ffffff
) !default;

/// High contrast backgrounds; High contrast elements
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-02'),
  map-get($carbon--theme, 'inverse-02'),
  #393939
) !default;

/// Error
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$support-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'support-01'),
  map-get($carbon--theme, 'support-01'),
  #da1e28
) !default;

/// Success
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$support-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'support-02'),
  map-get($carbon--theme, 'support-02'),
  #24a148
) !default;

/// Warning
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$support-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'support-03'),
  map-get($carbon--theme, 'support-03'),
  #f1c21b
) !default;

/// Information
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$support-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'support-04'),
  map-get($carbon--theme, 'support-04'),
  #0043ce
) !default;

/// Error on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-support-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-support-01'),
  map-get($carbon--theme, 'inverse-support-01'),
  #fa4d56
) !default;

/// Success on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-support-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-support-02'),
  map-get($carbon--theme, 'inverse-support-02'),
  #42be65
) !default;

/// Warning on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-support-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-support-03'),
  map-get($carbon--theme, 'inverse-support-03'),
  #f1c21b
) !default;

/// Information on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-support-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-support-04'),
  map-get($carbon--theme, 'inverse-support-04'),
  #4589ff
) !default;

/// Background overlay
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$overlay-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'overlay-01'),
  map-get($carbon--theme, 'overlay-01'),
  rgba(22, 22, 22, 0.5)
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$danger: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'danger'),
  map-get($carbon--theme, 'danger'),
  #da1e28
) !default;

/// Focus border; Focus underline
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$focus: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'focus'),
  map-get($carbon--theme, 'focus'),
  #0f62fe
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-focus-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-focus-ui'),
  map-get($carbon--theme, 'inverse-focus-ui'),
  #ffffff
) !default;

/// `$interactive-01` hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-primary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-primary'),
  map-get($carbon--theme, 'hover-primary'),
  #0353e9
) !default;

/// `$interactive-01` active
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$active-primary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-primary'),
  map-get($carbon--theme, 'active-primary'),
  #002d9c
) !default;

/// `$interactive-01` text hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-primary-text: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-primary-text'),
  map-get($carbon--theme, 'hover-primary-text'),
  #0043ce
) !default;

/// `$interactive-02` hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-secondary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-secondary'),
  map-get($carbon--theme, 'hover-secondary'),
  #4c4c4c
) !default;

/// `$interactive-02` active; `$inverse-01` active
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$active-secondary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-secondary'),
  map-get($carbon--theme, 'active-secondary'),
  #6f6f6f
) !default;

/// `$interactive-03` hover; `$inverse-01` hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-tertiary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-tertiary'),
  map-get($carbon--theme, 'hover-tertiary'),
  #0353e9
) !default;

/// `$interactive-03` active
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$active-tertiary: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-tertiary'),
  map-get($carbon--theme, 'active-tertiary'),
  #002d9c
) !default;

/// `$ui-01` hover; `$ui-02` hover; Transparent background hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-ui'),
  map-get($carbon--theme, 'hover-ui'),
  #e5e5e5
) !default;

/// `$ui-01` active; `$ui-02` active
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$active-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-ui'),
  map-get($carbon--theme, 'active-ui'),
  #c6c6c6
) !default;

/// Selected UI elements
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$selected-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'selected-ui'),
  map-get($carbon--theme, 'selected-ui'),
  #e0e0e0
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$selected-light-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'selected-light-ui'),
  map-get($carbon--theme, 'selected-light-ui'),
  #e0e0e0
) !default;

/// Data table selected row hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-selected-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-selected-ui'),
  map-get($carbon--theme, 'hover-selected-ui'),
  #cacaca
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$inverse-hover-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'inverse-hover-ui'),
  map-get($carbon--theme, 'inverse-hover-ui'),
  #4c4c4c
) !default;

/// Danger hover; `$support-01` hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-danger: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-danger'),
  map-get($carbon--theme, 'hover-danger'),
  #b81921
) !default;

/// Danger active; `$support-01` active
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$active-danger: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-danger'),
  map-get($carbon--theme, 'active-danger'),
  #750e13
) !default;

/// Row hover
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-row: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-row'),
  map-get($carbon--theme, 'hover-row'),
  #e5e5e5
) !default;

/// Visited links
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$visited-link: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'visited-link'),
  map-get($carbon--theme, 'visited-link'),
  #8a3ffc
) !default;

/// Disabled fields; Disabled backgrounds; Disabled border
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$disabled-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'disabled-01'),
  map-get($carbon--theme, 'disabled-01'),
  #f4f4f4
) !default;

/// Disabled elements on `$disabled-01`; Disabled label; Disabled text on `$disabled-01`; Disabled icons; Disabled border
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$disabled-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'disabled-02'),
  map-get($carbon--theme, 'disabled-02'),
  #c6c6c6
) !default;

/// Disabled text on `$disabled-02`; Disabled icons on `$disabled-02`
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$disabled-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'disabled-03'),
  map-get($carbon--theme, 'disabled-03'),
  #8d8d8d
) !default;

/// `$interactive-01` high light
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$highlight: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'highlight'),
  map-get($carbon--theme, 'highlight'),
  #d0e2ff
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$decorative-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'decorative-01'),
  map-get($carbon--theme, 'decorative-01'),
  #e0e0e0
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$hover-light-ui: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-light-ui'),
  map-get($carbon--theme, 'hover-light-ui'),
  #e5e5e5
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
$button-separator: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'button-separator'),
  map-get($carbon--theme, 'button-separator'),
  #e0e0e0
) !default;

/// Skeleton state of graphics
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$skeleton-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'skeleton-01'),
  map-get($carbon--theme, 'skeleton-01'),
  #e5e5e5
) !default;

/// Skeleton state of text
/// @type {undefined}
/// @access public
/// @group @carbon/themes
$skeleton-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'skeleton-02'),
  map-get($carbon--theme, 'skeleton-02'),
  #c6c6c6
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
/// @alias interactive-01
/// @deprecated
$brand-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'brand-01'),
  map-get($carbon--theme, 'brand-01'),
  #0f62fe
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
/// @alias interactive-02
/// @deprecated
$brand-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'brand-02'),
  map-get($carbon--theme, 'brand-02'),
  #393939
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
/// @alias interactive-03
/// @deprecated
$brand-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'brand-03'),
  map-get($carbon--theme, 'brand-03'),
  #0f62fe
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
/// @alias active-ui
/// @deprecated
$active-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'active-01'),
  map-get($carbon--theme, 'active-01'),
  #c6c6c6
) !default;

/// @type {undefined}
/// @access public
/// @group @carbon/themes
/// @alias hover-ui
/// @deprecated
$hover-field: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'hover-field'),
  map-get($carbon--theme, 'hover-field'),
  #e5e5e5
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$caption-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'caption-01'),
  map-get($carbon--theme, 'caption-01'),
  (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$label-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'label-01'),
  map-get($carbon--theme, 'label-01'),
  (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$helper-text-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'helper-text-01'),
  map-get($carbon--theme, 'helper-text-01'),
  (
    font-size: 0.75rem,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$body-short-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'body-short-01'),
  map-get($carbon--theme, 'body-short-01'),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$body-long-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'body-long-01'),
  map-get($carbon--theme, 'body-long-01'),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$body-short-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'body-short-02'),
  map-get($carbon--theme, 'body-short-02'),
  (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$body-long-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'body-long-02'),
  map-get($carbon--theme, 'body-long-02'),
  (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.5,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$code-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'code-01'),
  map-get($carbon--theme, 'code-01'),
  (
    font-family:
      unquote(
        "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$code-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'code-02'),
  map-get($carbon--theme, 'code-02'),
  (
    font-family:
      unquote(
        "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$heading-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'heading-01'),
  map-get($carbon--theme, 'heading-01'),
  (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-01'),
  map-get($carbon--theme, 'productive-heading-01'),
  (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$heading-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'heading-02'),
  map-get($carbon--theme, 'heading-02'),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-02'),
  map-get($carbon--theme, 'productive-heading-02'),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-03'),
  map-get($carbon--theme, 'productive-heading-03'),
  (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.4,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-04'),
  map-get($carbon--theme, 'productive-heading-04'),
  (
    font-size: 1.75rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-05'),
  map-get($carbon--theme, 'productive-heading-05'),
  (
    font-size: 2rem,
    font-weight: 400,
    line-height: 1.25,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-06: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-06'),
  map-get($carbon--theme, 'productive-heading-06'),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.199,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$productive-heading-07: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'productive-heading-07'),
  map-get($carbon--theme, 'productive-heading-07'),
  (
    font-size: 3.375rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-01'),
  map-get($carbon--theme, 'expressive-heading-01'),
  (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-02'),
  map-get($carbon--theme, 'expressive-heading-02'),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.5,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-03'),
  map-get($carbon--theme, 'expressive-heading-03'),
  (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.4,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.25rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 1.5rem,
        line-height: 1.334,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-04'),
  map-get($carbon--theme, 'expressive-heading-04'),
  (
    font-size: 1.75rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.75rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 2rem,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-05'),
  map-get($carbon--theme, 'expressive-heading-05'),
  (
    font-size: 2rem,
    font-weight: 400,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 300,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 300,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 300,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 300,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-heading-06: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-heading-06'),
  map-get($carbon--theme, 'expressive-heading-06'),
  (
    font-size: 2rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 600,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 600,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 600,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 600,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$expressive-paragraph-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'expressive-paragraph-01'),
  map-get($carbon--theme, 'expressive-paragraph-01'),
  (
    font-size: 1.5rem,
    font-weight: 300,
    line-height: 1.334,
    letter-spacing: 0,
    lg: (
      font-size: 1.75rem,
      line-height: 1.29,
    ),
    max: (
      font-size: 2rem,
      line-height: 1.25,
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$quotation-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'quotation-01'),
  map-get($carbon--theme, 'quotation-01'),
  (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.3,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 1.25rem,
        font-weight: 400,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 1.5rem,
        font-weight: 400,
        line-height: 1.334,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 1.75rem,
        font-weight: 400,
        line-height: 1.29,
        letter-spacing: 0,
      ),
      max: (
        font-size: 2rem,
        font-weight: 400,
        line-height: 1.25,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$quotation-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'quotation-02'),
  map-get($carbon--theme, 'quotation-02'),
  (
    font-size: 2rem,
    font-weight: 300,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        line-height: 1.22,
      ),
      lg: (
        font-size: 2.625rem,
        line-height: 1.19,
      ),
      xlg: (
        font-size: 3rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 3.75rem,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$display-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'display-01'),
  map-get($carbon--theme, 'display-01'),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$display-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'display-02'),
  map-get($carbon--theme, 'display-02'),
  (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.16,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$display-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'display-03'),
  map-get($carbon--theme, 'display-03'),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$display-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'display-04'),
  map-get($carbon--theme, 'display-04'),
  (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
        letter-spacing: -0.64px,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-01'),
  map-get($carbon--theme, 'spacing-01'),
  0.125rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-02'),
  map-get($carbon--theme, 'spacing-02'),
  0.25rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-03'),
  map-get($carbon--theme, 'spacing-03'),
  0.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-04'),
  map-get($carbon--theme, 'spacing-04'),
  0.75rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-05'),
  map-get($carbon--theme, 'spacing-05'),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-06: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-06'),
  map-get($carbon--theme, 'spacing-06'),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-07: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-07'),
  map-get($carbon--theme, 'spacing-07'),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-08: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-08'),
  map-get($carbon--theme, 'spacing-08'),
  2.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-09: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-09'),
  map-get($carbon--theme, 'spacing-09'),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-10: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-10'),
  map-get($carbon--theme, 'spacing-10'),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-11: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-11'),
  map-get($carbon--theme, 'spacing-11'),
  5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$spacing-12: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'spacing-12'),
  map-get($carbon--theme, 'spacing-12'),
  6rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$fluid-spacing-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'fluid-spacing-01'),
  map-get($carbon--theme, 'fluid-spacing-01'),
  0
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$fluid-spacing-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'fluid-spacing-02'),
  map-get($carbon--theme, 'fluid-spacing-02'),
  2vw
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$fluid-spacing-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'fluid-spacing-03'),
  map-get($carbon--theme, 'fluid-spacing-03'),
  5vw
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$fluid-spacing-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'fluid-spacing-04'),
  map-get($carbon--theme, 'fluid-spacing-04'),
  10vw
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-01'),
  map-get($carbon--theme, 'layout-01'),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-02'),
  map-get($carbon--theme, 'layout-02'),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-03'),
  map-get($carbon--theme, 'layout-03'),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-04'),
  map-get($carbon--theme, 'layout-04'),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-05'),
  map-get($carbon--theme, 'layout-05'),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-06: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-06'),
  map-get($carbon--theme, 'layout-06'),
  6rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$layout-07: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'layout-07'),
  map-get($carbon--theme, 'layout-07'),
  10rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$container-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'container-01'),
  map-get($carbon--theme, 'container-01'),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$container-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'container-02'),
  map-get($carbon--theme, 'container-02'),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$container-03: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'container-03'),
  map-get($carbon--theme, 'container-03'),
  2.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$container-04: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'container-04'),
  map-get($carbon--theme, 'container-04'),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$container-05: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'container-05'),
  map-get($carbon--theme, 'container-05'),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$icon-size-01: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'icon-size-01'),
  map-get($carbon--theme, 'icon-size-01'),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @carbon/themes
$icon-size-02: if(
  global-variable-exists('carbon--theme') and
    map-has-key($carbon--theme, 'icon-size-02'),
  map-get($carbon--theme, 'icon-size-02'),
  1.25rem
) !default;
